import "chartkick/chart.js"

const InitCharts = () => {
  const chart = Chartkick.charts['api_donut_chart']

  if (!chart) { return }

  let chartConfig = chart.getChartObject().config

  chartConfig.options.onClick = (evt) => {
    const points = evt.chart.getElementsAtEventForMode(evt, 'nearest', { intersect: true }, true);

    if (points.length) {
      const firstPoint = points[0];
      const label = evt.chart.data.labels[firstPoint.index];
      const event = new CustomEvent("refresh", { bubbles: true, detail: { label: label } });

      window.dispatchEvent(event);
    }
  }

  chart.getChartObject().update(chartConfig)
}

export {InitCharts}
