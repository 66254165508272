import { Turbo } from "@hotwired/turbo-rails"
import "../../controllers"

import 'adminos/adminos';

import { InitCocoonFields } from './modules/cocoon_fields';
import { InitClipboard } from './modules/clipboard';
import { InitTooltip } from './modules/tooltip';
import { InitCharts } from './modules/chart';

Turbo.session.drive = false

InitCocoonFields();

document.addEventListener('turbo:load', () => {
  InitClipboard();
  InitTooltip()
  InitCharts()
});
