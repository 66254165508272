import { Controller } from "@hotwired/stimulus"
import * as Turbo from "@hotwired/turbo"

export default class extends Controller {
  static targets = [ 'stats' ]

  connect() {
  }

  refresh(event) {
    const label = event.detail.label;
    console.log('refresh', label)

    const frame = document.querySelector('turbo-frame#api_requests')

    frame.src = `/admin/api_requests?label=${label}`
  }
}
